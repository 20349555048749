/**
 * Variables
*/
/* Typography */
/* Breakpoints */
/* Paths */
/* Grid setup */
/* Colour palette */
/* Components */
.input-field {
  margin-bottom: 12px; }
  .input-field:not(.otp-mobile-inputs) {
    position: relative;
    height: 61px;
    border: 2px solid #eaeaea;
    background: #fff;
    border-radius: 12px;
    padding: 25px 16px 15px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    color: #000;
    -webkit-transition: all ease 0.2s;
    transition: all ease 0.2s;
    font-size: 16px;
    letter-spacing: -0.3px;
    width: 100%;
    z-index: 1; }
  .input-field.select-box-active label {
    padding: 14px 16px 15px;
    font-size: 10px;
    letter-spacing: -0.19px; }
  .input-field.flagSelect {
    z-index: 2; }
  .input-field .revealPassword {
    position: absolute;
    right: 12px;
    top: 21px;
    width: 28px;
    z-index: 3; }
  .input-field.hide {
    opacity: 0;
    visibility: hidden; }
    @media (max-width: 1023px) {
      .input-field.hide {
        display: none; } }
  .input-field.active {
    border-color: #4200FF; }
  .input-field.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 2; }
    .input-field.open .custom-select::after,
    .input-field.open .custom-select-flag::after {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .input-field textarea.materialize-textarea {
    padding-top: 12px;
    height: 87px;
    font-weight: 400;
    font-family: 'Galano Grotesque', sans-serif; }
    .input-field textarea.materialize-textarea::-webkit-input-placeholder {
      font-weight: 400;
      font-family: 'Galano Grotesque', sans-serif; }
    .input-field textarea.materialize-textarea::-moz-placeholder {
      font-weight: 400;
      font-family: 'Galano Grotesque', sans-serif; }
    .input-field textarea.materialize-textarea:-ms-input-placeholder {
      font-weight: 400;
      font-family: 'Galano Grotesque', sans-serif; }
    .input-field textarea.materialize-textarea::-ms-input-placeholder {
      font-weight: 400;
      font-family: 'Galano Grotesque', sans-serif; }
    .input-field textarea.materialize-textarea::placeholder {
      font-weight: 400;
      font-family: 'Galano Grotesque', sans-serif; }
  .input-field.textarea {
    padding-top: 0;
    height: auto;
    margin-top: 12px; }
  .input-field input,
  .input-field textarea,
  .input-field .custom-select,
  .input-field .mobileNmuber,
  .input-field .custom-select-flag {
    border: 0;
    background: transparent;
    border-radius: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    color: #000;
    font-size: 16px;
    letter-spacing: -0.3px;
    width: 100%;
    position: relative;
    z-index: 2; }
    .input-field input::-webkit-credentials-auto-fill-button,
    .input-field textarea::-webkit-credentials-auto-fill-button,
    .input-field .custom-select::-webkit-credentials-auto-fill-button,
    .input-field .mobileNmuber::-webkit-credentials-auto-fill-button,
    .input-field .custom-select-flag::-webkit-credentials-auto-fill-button {
      margin: 0;
      width: 0;
      background-color: transparent; }
    .input-field input:disabled, .input-field input[readonly="readonly"],
    .input-field textarea:disabled,
    .input-field textarea[readonly="readonly"],
    .input-field .custom-select:disabled,
    .input-field .custom-select[readonly="readonly"],
    .input-field .mobileNmuber:disabled,
    .input-field .mobileNmuber[readonly="readonly"],
    .input-field .custom-select-flag:disabled,
    .input-field .custom-select-flag[readonly="readonly"] {
      color: #cccccc;
      opacity: 1; }
    .input-field input:disabled + label,
    .input-field input[readonly="readonly"] + label,
    .input-field textarea:disabled + label,
    .input-field textarea[readonly="readonly"] + label,
    .input-field .custom-select:disabled + label,
    .input-field .custom-select[readonly="readonly"] + label,
    .input-field .mobileNmuber:disabled + label,
    .input-field .mobileNmuber[readonly="readonly"] + label,
    .input-field .custom-select-flag:disabled + label,
    .input-field .custom-select-flag[readonly="readonly"] + label {
      color: #cccccc; }
    .input-field input:focus:not([readonly]),
    .input-field textarea:focus:not([readonly]),
    .input-field .custom-select:focus:not([readonly]),
    .input-field .mobileNmuber:focus:not([readonly]),
    .input-field .custom-select-flag:focus:not([readonly]) {
      border-color: #4200FF; }
    .input-field input.code,
    .input-field textarea.code,
    .input-field .custom-select.code,
    .input-field .mobileNmuber.code,
    .input-field .custom-select-flag.code {
      padding-left: 140px; }
      .input-field input.code .generated-list,
      .input-field textarea.code .generated-list,
      .input-field .custom-select.code .generated-list,
      .input-field .mobileNmuber.code .generated-list,
      .input-field .custom-select-flag.code .generated-list {
        width: 430px; }
        @media (max-width: 1360px) {
          .input-field input.code .generated-list,
          .input-field textarea.code .generated-list,
          .input-field .custom-select.code .generated-list,
          .input-field .mobileNmuber.code .generated-list,
          .input-field .custom-select-flag.code .generated-list {
            width: 294.5px; } }
    .input-field input .numberField,
    .input-field textarea .numberField,
    .input-field .custom-select .numberField,
    .input-field .mobileNmuber .numberField,
    .input-field .custom-select-flag .numberField {
      position: relative; }
      .input-field input .numberField label,
      .input-field textarea .numberField label,
      .input-field .custom-select .numberField label,
      .input-field .mobileNmuber .numberField label,
      .input-field .custom-select-flag .numberField label {
        padding: 0;
        top: -7px; }
        .input-field input .numberField label.active,
        .input-field textarea .numberField label.active,
        .input-field .custom-select .numberField label.active,
        .input-field .mobileNmuber .numberField label.active,
        .input-field .custom-select-flag .numberField label.active {
          margin-top: -10px;
          top: -4px; }
      .input-field input .numberField input,
      .input-field textarea .numberField input,
      .input-field .custom-select .numberField input,
      .input-field .mobileNmuber .numberField input,
      .input-field .custom-select-flag .numberField input {
        width: 100%; }
  .input-field .custom-select-search {
    border: 0;
    background: transparent;
    border-radius: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    color: #000;
    font-size: 16px;
    letter-spacing: -0.3px;
    position: relative;
    z-index: 2;
    margin-left: -17px;
    margin-right: -17px; }
    .input-field .custom-select-search select {
      width: 100% !important;
      opacity: 0; }
    .input-field .custom-select-search .select2-container {
      width: 100% !important; }
      .input-field .custom-select-search .select2-container .select2-selection--single {
        height: 28px;
        outline: 0;
        background: transparent;
        border: 0; }
        .input-field .custom-select-search .select2-container .select2-selection--single .select2-selection__rendered {
          padding-left: 16px;
          line-height: 20px; }
        .input-field .custom-select-search .select2-container .select2-selection--single .select2-selection__arrow {
          display: none; }
    .input-field .custom-select-search::after {
      content: "";
      position: absolute;
      right: 12px;
      top: -10px;
      background: url(../images/newdesktop/drop-arrow.png) no-repeat center top;
      background-size: 100%;
      width: 27px;
      height: 27px;
      -moz-transition: all ease 0.2s;
      -o-transition: all ease 0.2s;
      -webkit-transition: all ease 0.2s;
      transition: all ease 0.2s; }
  .input-field textarea {
    height: 122px; }
  .input-field.otp-mobile-inputs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .input-field.otp-mobile-inputs input[type="tel"]:not(.browser-default).singleOtp {
      width: 58px;
      height: 55px;
      border-radius: 10px;
      margin-right: 9px;
      background: #fff;
      border: 2px solid #eaeaea;
      padding: 0 10px;
      text-align: center; }
  .input-field input[type="number"]::-webkit-inner-spin-button,
  .input-field input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .input-field input:-webkit-autofill,
  .input-field input:-webkit-autofill:focus {
    -webkit-box-shadow: 0;
    -webkit-text-fill-color: #000;
    background: transparent; }
  .input-field input[type="number"] {
    -moz-appearance: textfield; }
  .input-field label {
    font-size: 16px;
    color: #000;
    padding: 20px 16px 15px;
    height: 61px;
    position: absolute;
    left: 0;
    top: -3px;
    letter-spacing: -0.6px;
    width: 100%;
    -webkit-transition: all ease 0.2s;
    transition: all ease 0.2s;
    z-index: 1;
    white-space: nowrap;
    font-size: 12px; }
    .input-field label.active, .input-field label.date-label, .input-field label.open-label {
      padding: 14px 16px 15px;
      font-size: 10px;
      letter-spacing: -0.19px;
      top: 0; }
    .input-field label.date-label, .input-field label.open-label {
      top: -4px; }
    .input-field label.invalid {
      color: #ff6262; }
  .input-field.custom-search-boxes label {
    padding: 14px 16px 15px;
    font-size: 10px;
    letter-spacing: -0.19px; }
  .input-field .custom-select,
  .input-field .custom-select-flag {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0; }
    .input-field .custom-select select,
    .input-field .custom-select-flag select {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      z-index: 3;
      opacity: 0;
      width: 100%;
      pointer-events: none; }
    .input-field .custom-select .generated-list,
    .input-field .custom-select-flag .generated-list {
      display: none;
      max-height: 318px;
      height: auto;
      background: #ffffff;
      overflow: scroll;
      position: absolute;
      top: 59px;
      border-bottom: solid 1px #cecece;
      border-top: solid 1px #cecece;
      border-left: solid 1px #cecece;
      border-right: solid 1px #cecece;
      z-index: 2;
      left: -1px;
      right: -1px;
      padding: 0;
      margin: 0;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px; }
      .input-field .custom-select .generated-list li,
      .input-field .custom-select-flag .generated-list li {
        border-top: solid 1px #cecece;
        height: 53px;
        letter-spacing: -0.6px;
        font-size: 16px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        color: #000;
        -moz-transition: all ease 0.2s;
        -o-transition: all ease 0.2s;
        -webkit-transition: all ease 0.2s;
        transition: all ease 0.2s;
        padding: 15px; }
        .input-field .custom-select .generated-list li b,
        .input-field .custom-select-flag .generated-list li b {
          font-weight: normal;
          width: 76%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          direction: ltr; }
        .input-field .custom-select .generated-list li:first-child,
        .input-field .custom-select-flag .generated-list li:first-child {
          border-top: 0; }
        .input-field .custom-select .generated-list li:hover,
        .input-field .custom-select-flag .generated-list li:hover {
          background: #e7e7e7; }
    .input-field .custom-select span,
    .input-field .custom-select-flag span {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      padding: 28px 16px 15px;
      color: #000;
      z-index: 1;
      line-height: 1;
      font-size: 16px;
      letter-spacing: -0.3px; }
    .input-field .custom-select label,
    .input-field .custom-select-flag label {
      padding: 14px 16px 15px;
      font-size: 10px;
      letter-spacing: -0.19px;
      top: -3px; }
    .input-field .custom-select::after,
    .input-field .custom-select-flag::after {
      content: "";
      position: absolute;
      right: 10px;
      top: 17px;
      background: url(../images/newdesktop/drop-arrow.png) no-repeat center top;
      background-size: 100%;
      width: 27px;
      height: 27px;
      -moz-transition: all ease 0.2s;
      -o-transition: all ease 0.2s;
      -webkit-transition: all ease 0.2s;
      transition: all ease 0.2s; }
    .input-field .custom-select.is-disabled span,
    .input-field .custom-select-flag.is-disabled span {
      color: #9b9b9b; }
  .input-field .mobileNmuber {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0; }
    .input-field .mobileNmuber .custom-select,
    .input-field .mobileNmuber .custom-select-flag {
      border: 0;
      background: transparent;
      border-radius: 0;
      width: 140px;
      border-right: 1px solid #e7e7e7;
      left: -16px;
      top: -25px;
      bottom: -11px;
      right: -16px; }
    .input-field .mobileNmuber input {
      border: 0;
      background: transparent;
      border-radius: 0; }
    .input-field .mobileNmuber .numberField {
      position: relative;
      width: 100%; }
    .input-field .mobileNmuber.open .custom-select::after,
    .input-field .mobileNmuber.open .custom-select-flag::after {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .input-field .jq-dte .separator {
    margin-right: 8px; }
  .input-field.custom-amount-field {
    padding-top: 21px; }
    .input-field.custom-amount-field input {
      text-align: center; }

.mobile-verification-section .input-field {
  z-index: 3; }

#update-profile-form .form-action-box {
  width: 158px; }

.edit-phone-form .input-field.otp-mobile-inputs {
  position: relative;
  margin-top: 30px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
  .edit-phone-form .input-field.otp-mobile-inputs .invalid-feedback {
    font-size: 10px;
    color: red;
    position: absolute;
    top: -21px;
    left: 0; }

.edit-phone-form .input-field .mobileNmuber.code .generated-list {
  width: 340px; }

.registeration_agreement .checkbox-styling {
  font-size: 13px;
  line-height: 15px;
  letter-spacing: -0.283333px;
  color: #000;
  position: relative;
  padding-left: 35px;
  margin-bottom: 11px;
  height: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .registeration_agreement .checkbox-styling a {
    color: #000;
    text-decoration: underline;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif; }
  .registeration_agreement .checkbox-styling input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 25px;
    width: 25px;
    left: 0;
    top: 0;
    z-index: 1; }
    .registeration_agreement .checkbox-styling input:checked ~ .checkmark:after {
      display: block; }
  .registeration_agreement .checkbox-styling .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border: 2px solid #eaeaea;
    border-radius: 5px; }
    .registeration_agreement .checkbox-styling .checkmark:after {
      content: "";
      position: absolute;
      display: none;
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: 2px solid #000;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg); }

.registeration_agreement .invalid-feedback {
  position: absolute;
  left: 35px;
  bottom: -9px;
  font-size: 10px;
  color: red; }

#change-password-form .form-action-box {
  width: 203px; }
  #change-password-form .form-action-box button {
    width: 100%; }

.radio-button-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0;
  height: 61px;
  border-radius: 10px;
  letter-spacing: -0.38px;
  font-size: 15px;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  -webkit-box-flex: inherit;
      -ms-flex-positive: inherit;
          flex-grow: inherit;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .radio-button-group .input-field {
    margin-top: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-right: 17px;
    width: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0;
    background: transparent;
    height: auto;
    border: 0;
    border-radius: 0;
    padding: 0; }
    .radio-button-group .input-field span {
      width: 33px;
      height: 33px;
      background: url(../images/m/icons/radio@3x.png) no-repeat center top;
      background-size: 100%;
      margin-right: 9px;
      display: inline-block; }
    .radio-button-group .input-field.active {
      color: #4200FF; }
      .radio-button-group .input-field.active span {
        background: url(../images/m/icons/radiocheck@3x.png) no-repeat center top;
        background-size: 100%; }

#registeration-step-2 {
  display: none; }
  #registeration-step-2 p {
    margin-bottom: 20px; }

.input-field.invalid, .input-field.invalid:focus, .input-field.is-invalid:focus, .input-field.is-invalid, .input-field.in-valid, .input-field input.invalid, .input-field input.invalid:focus, .input-field input.is-invalid:focus, .input-field input.is-invalid,
.input-field textarea.invalid,
.input-field textarea.invalid:focus,
.input-field textarea.is-invalid:focus,
.input-field textarea.is-invalid,
.input-field .custom-select.invalid,
.input-field .custom-select.invalid:focus,
.input-field .custom-select.is-invalid:focus,
.input-field .custom-select.is-invalid,
.input-field .mobileNmuber.invalid,
.input-field .mobileNmuber.invalid:focus,
.input-field .mobileNmuber.is-invalid:focus,
.input-field .mobileNmuber.is-invalid,
.input-field .custom-select-flag.invalid,
.input-field .custom-select-flag.invalid:focus,
.input-field .custom-select-flag.is-invalid:focus,
.input-field .custom-select-flag.is-invalid {
  border-color: #ff6262; }

.select2-container--default .select2-dropdown {
  background-color: white;
  border: 2px solid #eaeaea;
  border-radius: 10px; }

.select2-container--default.select2-container--open .select2-dropdown--below {
  padding-top: 10px; }
  .select2-container--default.select2-container--open .select2-dropdown--below::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 6px;
    height: 1px;
    background: #cecece; }

.select2-container--default .select2-search--dropdown {
  padding: 0; }
  .select2-container--default .select2-search--dropdown .select2-search__field {
    border: 0 !important;
    border-bottom: 1px solid #cecece !important;
    letter-spacing: -0.6px;
    font-size: 16px;
    height: 47px;
    padding: 8px 16px;
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }

.select2-container--default .select2-results {
  display: block; }
  .select2-container--default .select2-results .select2-results__options {
    max-height: 200px; }
  .select2-container--default .select2-results .select2-results__options li {
    border-bottom: solid 1px #cecece;
    height: 47px;
    letter-spacing: -0.6px;
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    color: #000;
    cursor: pointer;
    -moz-transition: all ease 0.2s;
    -o-transition: all ease 0.2s;
    -webkit-transition: all ease 0.2s;
    transition: all ease 0.2s;
    padding: 15px; }
    .select2-container--default .select2-results .select2-results__options li b {
      font-weight: normal;
      width: 76%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      direction: ltr; }
    .select2-container--default .select2-results .select2-results__options li:first-child {
      border-top: 0; }
    .select2-container--default .select2-results .select2-results__options li:hover {
      background: #e7e7e7; }

.select2-container--default .select2-results__option--highlighted[aria-selected="true"],
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: transparent !important;
  color: #4a4a4a !important; }

.otp-method {
  margin-top: 12px; }
  .otp-method p {
    font-size: 16px;
    letter-spacing: -0.283px;
    margin: 0 0 12px; }
  .otp-method ul {
    list-style: none;
    margin-bottom: 12px;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    .otp-method ul li {
      width: 49%; }
      .otp-method ul li a {
        background: #fff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-size: 12px;
        letter-spacing: -0.189px;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        border-radius: 12px;
        height: 87px;
        color: #000;
        border: 3px solid #efefef; }
        .otp-method ul li a img {
          margin-bottom: 5px; }
      .otp-method ul li.active a {
        border: 3px solid #4200FF; }

.stripe-form-group {
  position: relative;
  height: 61px;
  border: 2px solid #eaeaea;
  background: #fff;
  border-radius: 12px;
  padding: 7px 16px 7px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #000;
  -webkit-transition: all ease 0.2s;
  transition: all ease 0.2s;
  font-size: 16px;
  letter-spacing: -0.3px;
  width: 100%;
  z-index: 1;
  margin-top: 16px;
  position: relative; }
  .stripe-form-group span:not(.brand) {
    font-size: 10px;
    letter-spacing: -0.19px;
    color: #000;
    margin-bottom: -2px; }
  .stripe-form-group .field {
    margin-top: -3px; }
  .stripe-form-group .brand {
    position: absolute;
    right: 11px;
    top: 13px; }
    .stripe-form-group .brand .pf {
      width: 47px;
      height: 31px;
      background: url(../images/m/components/empty-3.png) left top no-repeat;
      background-size: 100%;
      display: inline-block; }
      .stripe-form-group .brand .pf.pf-visa {
        background: url(../images/visa-dark.png) no-repeat left top;
        background-size: 100%;
        height: 36px; }
      .stripe-form-group .brand .pf.pf-mastercard {
        background: url(../images/master-dark.png) no-repeat left top;
        background-size: 100%;
        height: 36px; }
      .stripe-form-group .brand .pf.pf-american-express {
        background: url(../images/amex-dark.png) no-repeat left top;
        background-size: 100%;
        height: 36px; }
      .stripe-form-group .brand .pf.pf-diners {
        background: url(../images/m/components/dc@3x.png) no-repeat left top;
        background-size: 100%;
        height: 31px; }
      .stripe-form-group .brand .pf.pf-jcb {
        background: url(../images/m/components/jcb@3x.png) no-repeat left top;
        background-size: 100%;
        height: 31px; }
      .stripe-form-group .brand .pf.pf-discover {
        background: url(../images/m/components/discover@3x.png) no-repeat left top;
        background-size: 100%;
        height: 31px; }

.stripe-form-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .stripe-form-column .stripe-form-group {
    width: 48%; }

.stripe-custom-checkbox {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 12px;
  color: #696969;
  letter-spacing: -0.2px;
  display: inline-block; }
  .stripe-custom-checkbox .custom-control-label {
    position: relative;
    top: -2px;
    margin-left: 5px; }

.payment-module-action button {
  width: 100%; }

.stripe-add-new-card-title {
  margin: 10px 0;
  letter-spacing: -0.4px;
  font-size: 18px;
  margin: 0 0 10px;
  padding: 0;
  color: #000;
  font-weight: 400;
  font-family: 'Galano Grotesque Black', sans-serif; }

.stripe-saved-cards-module .form-control-label {
  margin: 10px 0;
  letter-spacing: -0.4px;
  font-size: 18px;
  margin: 0 0 10px;
  padding: 0;
  color: #000;
  display: block;
  font-weight: 400;
  font-family: 'Galano Grotesque Black', sans-serif; }

.stripe-saved-cards-module .form-check {
  border-radius: 20px;
  margin-bottom: 12px;
  position: relative;
  background: #ffffff;
  background-clip: padding-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 0 0 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #c9c9c9; }
  .stripe-saved-cards-module .form-check .form-check-label {
    letter-spacing: -0.39px;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0;
    padding: 0 0 0 34px;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .stripe-saved-cards-module .form-check .form-check-label .card-image {
      width: 48px;
      height: 31px;
      margin-right: 11px;
      background: url(../images/m/components/empty-3.png) left top no-repeat;
      background-size: 100%; }
      .stripe-saved-cards-module .form-check .form-check-label .card-image.visa {
        background: url(../images/visa-dark.png) no-repeat left top;
        background-size: 100%;
        height: 36px; }
      .stripe-saved-cards-module .form-check .form-check-label .card-image.master {
        background: url(../images/master-dark.png) no-repeat left top;
        background-size: 100%;
        height: 36px; }
      .stripe-saved-cards-module .form-check .form-check-label .card-image.amex {
        background: url(../images/amex-dark.png) no-repeat left top;
        background-size: 100%;
        height: 36px; }
      .stripe-saved-cards-module .form-check .form-check-label .card-image.diners {
        background: url(../images/m/components/dc@3x.png) no-repeat left top;
        background-size: 100%;
        height: 31px; }
      .stripe-saved-cards-module .form-check .form-check-label .card-image.jcb {
        background: url(../images/m/components/jcb@3x.png) no-repeat left top;
        background-size: 100%;
        height: 31px; }
      .stripe-saved-cards-module .form-check .form-check-label .card-image.discover {
        background: url(../images/m/components/discover@3x.png) no-repeat left top;
        background-size: 100%;
        height: 31px; }
    .stripe-saved-cards-module .form-check .form-check-label span {
      display: block;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.18px;
      color: #000;
      font-weight: 400;
      font-family: 'Galano Grotesque', sans-serif; }
    .stripe-saved-cards-module .form-check .form-check-label:after {
      content: '';
      position: absolute;
      border: 1px solid #d8d8d8;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 12px;
      display: block;
      left: 0;
      top: 7px; }
  .stripe-saved-cards-module .form-check .form-check-input:checked + .saved-card-elements.form-check-label:before {
    content: "";
    background: #4200FF;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    position: absolute;
    left: 4px;
    top: 11px; }

.payment-wallet .stripe-saved-cards-module .form-check {
  padding: 20px; }
  .payment-wallet .stripe-saved-cards-module .form-check .form-check-label:after {
    left: 20px;
    top: 27px; }
  .payment-wallet .stripe-saved-cards-module .form-check .form-check-input:checked + .saved-card-elements.form-check-label:before {
    left: 24px;
    top: 31px; }

.switch-to-add-card,
.saved-cards.form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  font-size: 14px;
  letter-spacing: -0.1px;
  text-align: right;
  padding: 19px 0;
  cursor: pointer; }
  .switch-to-add-card a,
  .saved-cards.form-group a {
    color: #4200FF;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif; }

#new-card-form-container .custom-checkbox input {
  -webkit-appearance: auto;
     -moz-appearance: auto;
          appearance: auto; }

#registerLoginBox {
  padding: 110px 0; }

.loginBox,
.registerBox,
.newBoxLoginRegiser {
  max-width: 595px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 43px 82px 46px;
  background: #fff;
  margin: 0 auto 26px;
  -webkit-box-shadow: 4px 6px 38px rgba(0, 0, 0, 0.12);
          box-shadow: 4px 6px 38px rgba(0, 0, 0, 0.12);
  border-radius: 30px; }
  .loginBox .login-box .d-flex,
  .registerBox .login-box .d-flex,
  .newBoxLoginRegiser .login-box .d-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .loginBox .login-box .formAction,
  .registerBox .login-box .formAction,
  .newBoxLoginRegiser .login-box .formAction {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    .loginBox .login-box .formAction .resendSms.disabled,
    .registerBox .login-box .formAction .resendSms.disabled,
    .newBoxLoginRegiser .login-box .formAction .resendSms.disabled {
      pointer-events: none;
      display: none; }
  .loginBox h2,
  .registerBox h2,
  .newBoxLoginRegiser h2 {
    margin: 0 0 18px;
    letter-spacing: -1.2px;
    font-size: 30px;
    line-height: 27px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-weight: 400;
    font-family: 'Galano Grotesque Bold', sans-serif; }
    .loginBox h2 img,
    .registerBox h2 img,
    .newBoxLoginRegiser h2 img {
      width: 19px;
      display: none; }
    .loginBox h2 .js-close-modal-box-trigger img,
    .registerBox h2 .js-close-modal-box-trigger img,
    .newBoxLoginRegiser h2 .js-close-modal-box-trigger img {
      display: block; }
  .loginBox .resend-area .resendSmsRegister,
  .registerBox .resend-area .resendSmsRegister,
  .newBoxLoginRegiser .resend-area .resendSmsRegister {
    display: block;
    text-align: center; }
    .loginBox .resend-area .resendSmsRegister.disabled,
    .registerBox .resend-area .resendSmsRegister.disabled,
    .newBoxLoginRegiser .resend-area .resendSmsRegister.disabled {
      display: none; }
  .loginBox .resend-area .timerText,
  .registerBox .resend-area .timerText,
  .newBoxLoginRegiser .resend-area .timerText {
    text-align: center;
    display: block; }
  .loginBox .global-btn,
  .registerBox .global-btn,
  .newBoxLoginRegiser .global-btn {
    width: 211px;
    float: right;
    font-size: 16px; }
  .loginBox .action-box::before, .loginBox .action-box::after,
  .registerBox .action-box::before,
  .registerBox .action-box::after,
  .newBoxLoginRegiser .action-box::before,
  .newBoxLoginRegiser .action-box::after {
    content: "";
    clear: both; }
  .loginBox .action-box::after,
  .registerBox .action-box::after,
  .newBoxLoginRegiser .action-box::after {
    display: table; }
  .loginBox .new-columns,
  .registerBox .new-columns,
  .newBoxLoginRegiser .new-columns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 26px; }
  .loginBox.instantCash .revealPassword,
  .registerBox.instantCash .revealPassword,
  .newBoxLoginRegiser.instantCash .revealPassword {
    position: absolute;
    right: 16px;
    top: 15px;
    border: 1px solid #DFDFDF;
    border-radius: 75px;
    width: 62px;
    height: 29px;
    line-height: 20px;
    letter-spacing: -0.383801px;
    font-size: 11px;
    color: #696969;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-weight: 400;
    font-family: 'Galano Grotesque Medium', sans-serif; }
  .loginBox.instantCash .successful-instant-register,
  .registerBox.instantCash .successful-instant-register,
  .newBoxLoginRegiser.instantCash .successful-instant-register {
    display: none;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 80px; }
    .loginBox.instantCash .successful-instant-register.show,
    .registerBox.instantCash .successful-instant-register.show,
    .newBoxLoginRegiser.instantCash .successful-instant-register.show {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
    .loginBox.instantCash .successful-instant-register img,
    .registerBox.instantCash .successful-instant-register img,
    .newBoxLoginRegiser.instantCash .successful-instant-register img {
      margin-bottom: 11px;
      width: 72px;
      height: 72px; }
    .loginBox.instantCash .successful-instant-register p,
    .registerBox.instantCash .successful-instant-register p,
    .newBoxLoginRegiser.instantCash .successful-instant-register p {
      font-size: 20px;
      line-height: 25px;
      text-align: center;
      letter-spacing: -0.6px;
      color: #000000;
      margin: 0 0 23px;
      width: 80%;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
    .loginBox.instantCash .successful-instant-register a.global-button,
    .registerBox.instantCash .successful-instant-register a.global-button,
    .newBoxLoginRegiser.instantCash .successful-instant-register a.global-button {
      font-size: 16px !important; }
  .loginBox.instantCash .formAction,
  .registerBox.instantCash .formAction,
  .newBoxLoginRegiser.instantCash .formAction {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
  .loginBox p,
  .registerBox p,
  .newBoxLoginRegiser p {
    text-align: center; }

.loginBox.close {
  cursor: pointer; }
  .loginBox.close h2 {
    margin-bottom: 0; }
    .loginBox.close h2 img {
      display: inline-block; }
  .loginBox.close form {
    display: none; }

.loginBox a {
  font-size: 13px;
  color: #9b9b9b;
  width: 60%;
  font-weight: 400;
  font-family: 'Galano Grotesque', sans-serif; }

.loginBox .checkbox-styling a {
  width: auto;
  display: inline-block; }

.loginBox .formAction {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.loginBox .dualColumn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .loginBox .dualColumn .smaller {
    width: 30%;
    position: relative; }
    .loginBox .dualColumn .smaller .flag-sm {
      position: absolute;
      left: 16px;
      bottom: 16px; }
    .loginBox .dualColumn .smaller .form-control {
      padding-left: 30px;
      padding-top: 3px; }
      .loginBox .dualColumn .smaller .form-control:disabled {
        color: #000; }
  .loginBox .dualColumn .bigger {
    width: 68%; }

.registerBox.close {
  cursor: pointer; }
  .registerBox.close h2 {
    margin-bottom: 0; }
    .registerBox.close h2 img {
      display: inline-block; }
  .registerBox.close #registeration-step-1 > div {
    display: none; }

.registerBox .registeration-step-1 > div {
  display: block; }

.registerBox .resendSms {
  text-align: center;
  display: block;
  margin-bottom: 5px;
  display: block;
  font-size: 14px;
  margin-top: 20px; }
  .registerBox .resendSms.disabled {
    pointer-events: none;
    display: none; }

.registerBox .timerText {
  text-align: center;
  display: block;
  color: #9b9b9b;
  font-size: 14px;
  margin-top: 20px; }

.header-regi {
  font-weight: 400;
  font-family: 'Galano Grotesque Bold', sans-serif;
  font-size: 20px;
  line-height: 22.7px;
  letter-spacing: -0.7px;
  margin-bottom: 21px; }
  .header-regi span {
    color: #FF006B; }

.timerText {
  width: 100%;
  text-align: center; }

.new-login-tabs {
  list-style: none;
  margin: 24px 0 14px 0;
  padding: 0;
  background: #EAEAEA;
  border-radius: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 61px; }
  .new-login-tabs li {
    width: 50%;
    height: 100%; }
    .new-login-tabs li a {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      width: 100%;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      height: 100%;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: -0.283333px;
      color: #000;
      border-radius: 16px; }
    .new-login-tabs li.active a {
      background: #fff;
      -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.15);
              box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.15);
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }

.register-box {
  display: none; }

#enocContainer {
  padding: 0 0;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  height: 100%;
  position: relative;
  z-index: 2; }
  #enocContainer .custom-select {
    height: 59px; }
  #enocContainer .link-arabic {
    color: #fff;
    font-family: "Cairo", sans-serif;
    font-weight: 700;
    position: absolute;
    font-size: 22px;
    left: 50%;
    top: 15px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  #enocContainer .link-english {
    color: #fff;
    font-family: 'Lilita One', cursive;
    font-weight: 400;
    position: absolute;
    font-size: 22px;
    left: 50%;
    top: 15px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  #enocContainer .enoc-container-left {
    width: 57%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 100%;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    #enocContainer .enoc-container-left .main-header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      margin-bottom: 15%;
      position: absolute;
      left: 0;
      top: 0; }
      #enocContainer .enoc-container-left .main-header.dsf {
        right: 0; }
        #enocContainer .enoc-container-left .main-header.dsf header {
          width: 60%; }
    #enocContainer .enoc-container-left header {
      background: #fff;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
      padding: 30px 30px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      margin-bottom: 30px;
      margin-left: auto;
      margin-right: auto;
      width: 40%; }
    #enocContainer .enoc-container-left.stake header {
      width: 60%; }
    #enocContainer .enoc-container-left .secondary-img {
      width: 50%;
      margin-top: 20px; }
    #enocContainer .enoc-container-left .main-img {
      width: 100%; }
  #enocContainer .enoc-container-right {
    width: 40%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative; }
    #enocContainer .enoc-container-right .description {
      color: #fff;
      font-size: 16px;
      letter-spacing: -0.2px;
      margin: 0 0 20px;
      font-weight: 400;
      font-family: 'Galano Grotesque Bold', sans-serif; }
      #enocContainer .enoc-container-right .description span {
        font-size: 18px; }
    #enocContainer .enoc-container-right form {
      width: 100%; }
      #enocContainer .enoc-container-right form button {
        width: 100%;
        margin-right: 0; }
    #enocContainer .enoc-container-right .enoc-account,
    #enocContainer .enoc-container-right .enoc-login-box {
      display: none;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      width: 100%;
      padding: 30px 20px;
      background: #3139b3;
      border-top-right-radius: 25px;
      border-top-left-radius: 25px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
              box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
      margin-bottom: 0;
      max-height: 90%; }
      #enocContainer .enoc-container-right .enoc-account.bottom,
      #enocContainer .enoc-container-right .enoc-login-box.bottom {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px; }
      #enocContainer .enoc-container-right .enoc-account.full,
      #enocContainer .enoc-container-right .enoc-login-box.full {
        border-top-right-radius: 25px;
        border-top-left-radius: 25px;
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px; }
      #enocContainer .enoc-container-right .enoc-account.enoc-login, #enocContainer .enoc-container-right .enoc-account.enoc-register,
      #enocContainer .enoc-container-right .enoc-login-box.enoc-login,
      #enocContainer .enoc-container-right .enoc-login-box.enoc-register {
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px; }
      #enocContainer .enoc-container-right .enoc-account .scrollbar,
      #enocContainer .enoc-container-right .enoc-login-box .scrollbar {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
        overflow: auto;
        max-height: 100%;
        width: 100%; }
        #enocContainer .enoc-container-right .enoc-account .scrollbar::-webkit-scrollbar,
        #enocContainer .enoc-container-right .enoc-login-box .scrollbar::-webkit-scrollbar {
          display: none; }
      #enocContainer .enoc-container-right .enoc-account .register1,
      #enocContainer .enoc-container-right .enoc-account .register2,
      #enocContainer .enoc-container-right .enoc-login-box .register1,
      #enocContainer .enoc-container-right .enoc-login-box .register2 {
        width: 100%; }
      #enocContainer .enoc-container-right .enoc-account .title-enoc,
      #enocContainer .enoc-container-right .enoc-login-box .title-enoc {
        color: #fff;
        text-transform: uppercase;
        font-size: 24px;
        margin: 0 0 2px;
        font-weight: normal;
        line-height: 26px;
        text-align: center;
        font-weight: normal;
        font-family: 'Lilita One', cursive;
        font-weight: 900; }
        #enocContainer .enoc-container-right .enoc-account .title-enoc.small,
        #enocContainer .enoc-container-right .enoc-login-box .title-enoc.small {
          width: 80%;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 30px; }
      #enocContainer .enoc-container-right .enoc-account .description-enoc,
      #enocContainer .enoc-container-right .enoc-account p,
      #enocContainer .enoc-container-right .enoc-login-box .description-enoc,
      #enocContainer .enoc-container-right .enoc-login-box p {
        color: #fff;
        margin: 0 0 30px;
        font-weight: normal;
        text-align: center;
        font-weight: 400;
        font-family: 'Galano Grotesque', sans-serif; }
      #enocContainer .enoc-container-right .enoc-account .input-field,
      #enocContainer .enoc-container-right .enoc-account .mobile-verification-section,
      #enocContainer .enoc-container-right .enoc-login-box .input-field,
      #enocContainer .enoc-container-right .enoc-login-box .mobile-verification-section {
        width: 100%; }
        #enocContainer .enoc-container-right .enoc-account .input-field .form-control:focus,
        #enocContainer .enoc-container-right .enoc-account .mobile-verification-section .form-control:focus,
        #enocContainer .enoc-container-right .enoc-login-box .input-field .form-control:focus,
        #enocContainer .enoc-container-right .enoc-login-box .mobile-verification-section .form-control:focus {
          border-color: #fff; }
      #enocContainer .enoc-container-right .enoc-account .flexed-coumns,
      #enocContainer .enoc-container-right .enoc-login-box .flexed-coumns {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between; }
        #enocContainer .enoc-container-right .enoc-account .flexed-coumns .input-field,
        #enocContainer .enoc-container-right .enoc-login-box .flexed-coumns .input-field {
          width: 49%; }
      #enocContainer .enoc-container-right .enoc-account .enoc-code,
      #enocContainer .enoc-container-right .enoc-login-box .enoc-code {
        position: relative; }
        #enocContainer .enoc-container-right .enoc-account .enoc-code .add-code,
        #enocContainer .enoc-container-right .enoc-login-box .enoc-code .add-code {
          position: absolute;
          right: 13px;
          top: 20px;
          width: 25px;
          height: 25px;
          background-color: #c4c4c4;
          border-radius: 50%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          z-index: 2; }
      #enocContainer .enoc-container-right .enoc-account .registeration_agreement,
      #enocContainer .enoc-container-right .enoc-login-box .registeration_agreement {
        width: 100%; }
        #enocContainer .enoc-container-right .enoc-account .registeration_agreement .checkbox-styling,
        #enocContainer .enoc-container-right .enoc-login-box .registeration_agreement .checkbox-styling {
          color: #fff; }
          #enocContainer .enoc-container-right .enoc-account .registeration_agreement .checkbox-styling a,
          #enocContainer .enoc-container-right .enoc-login-box .registeration_agreement .checkbox-styling a {
            color: #fff; }
          #enocContainer .enoc-container-right .enoc-account .registeration_agreement .checkbox-styling .checkmark,
          #enocContainer .enoc-container-right .enoc-login-box .registeration_agreement .checkbox-styling .checkmark {
            background: #fff;
            border-color: #fff; }
            #enocContainer .enoc-container-right .enoc-account .registeration_agreement .checkbox-styling .checkmark:after,
            #enocContainer .enoc-container-right .enoc-login-box .registeration_agreement .checkbox-styling .checkmark:after {
              color: #000; }
      #enocContainer .enoc-container-right .enoc-account input,
      #enocContainer .enoc-container-right .enoc-login-box input {
        width: 100%; }
      #enocContainer .enoc-container-right .enoc-account.show,
      #enocContainer .enoc-container-right .enoc-login-box.show {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
    #enocContainer .enoc-container-right #mobile-exiting-user-button,
    #enocContainer .enoc-container-right #mobile-forgotPassword,
    #enocContainer .enoc-container-right .link--kukuri {
      color: #fff;
      display: block;
      text-align: center; }
    #enocContainer .enoc-container-right .forgot-password-success {
      display: none; }
    #enocContainer .enoc-container-right #mobile-forgotPassword,
    #enocContainer .enoc-container-right .link--kukuri {
      margin-bottom: 8px; }
    #enocContainer .enoc-container-right .action-box {
      width: 100%;
      position: relative;
      margin-bottom: 22px; }
      #enocContainer .enoc-container-right .action-box .enoc-button,
      #enocContainer .enoc-container-right .action-box .mobile-button {
        font-size: 24px;
        font-weight: normal;
        line-height: 26px;
        color: #000;
        background: #fca823;
        background: -webkit-gradient(linear, left top, right top, from(#f9e648), to(#fca823));
        background: linear-gradient(90deg, #f9e648 0%, #fca823 100%);
        border: 1px solid #fff74d;
        padding-bottom: 8px;
        padding-top: 18px;
        height: 60px;
        text-transform: uppercase;
        position: relative;
        z-index: 2;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        font-family: 'Lilita One', cursive;
        font-weight: 900; }
        #enocContainer .enoc-container-right .action-box .enoc-button:hover,
        #enocContainer .enoc-container-right .action-box .mobile-button:hover {
          background: #fca823;
          background: -webkit-gradient(linear, left top, right top, from(#fca823), to(#f9e648));
          background: linear-gradient(90deg, #fca823 0%, #f9e648 100%); }
        #enocContainer .enoc-container-right .action-box .enoc-button.is-disabled,
        #enocContainer .enoc-container-right .action-box .mobile-button.is-disabled {
          background: #e3e3e3; }
          #enocContainer .enoc-container-right .action-box .enoc-button.is-disabled:hover,
          #enocContainer .enoc-container-right .action-box .mobile-button.is-disabled:hover {
            background: #e3e3e3; }
      #enocContainer .enoc-container-right .action-box:after {
        content: '';
        background: #fc4723;
        height: 46px;
        border-radius: 15px;
        position: absolute;
        left: 0;
        bottom: -8px;
        right: 0;
        z-index: 1px; }
    #enocContainer .enoc-container-right .timerText {
      color: #fff; }
  #enocContainer a {
    color: #fff; }

.enoc-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  padding: 20px 0;
  z-index: 1; }
  .enoc-footer .footer-contianer {
    width: 80%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    margin: 0 auto; }
    .enoc-footer .footer-contianer img {
      width: 55%; }

.jq-dte-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 200px; }
  .jq-dte-inner .jq-dte-month,
  .jq-dte-inner .jq-dte-year {
    text-align: center;
    margin-right: 10px; }

.jq-dte-tooltip {
  height: 34px;
  padding: 0 15px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  white-space: pre;
  border-radius: 25px;
  -webkit-box-shadow: 0 4px 11px rgba(86, 86, 86, 0.3);
          box-shadow: 0 4px 11px rgba(86, 86, 86, 0.3);
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.226667px;
  color: #000;
  background: #fff;
  z-index: 2;
  -moz-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  -webkit-transition: all ease 0.2s;
  transition: all ease 0.2s;
  font-weight: 400;
  font-family: 'Galano Grotesque Medium', sans-serif; }

.jq-dte #birthday {
  width: 115px; }

.ticket-stack {
  position: relative;
  margin-left: -2px;
  margin-right: -2px;
  z-index: 2;
  text-align: center;
  overflow: hidden;
  background: url(../images/components/activeTickets-bg@24x.png) no-repeat left top;
  background-size: 100%;
  -webkit-transition: all ease 0.1s;
  transition: all ease 0.1s;
  height: 203px; }
  .ticket-stack .ticket-information-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 27px; }
    .ticket-stack .ticket-information-block .ticket-information {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 70%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start; }
      .ticket-stack .ticket-information-block .ticket-information span {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        font-size: 10px; }
        .ticket-stack .ticket-information-block .ticket-information span.dateFixer {
          display: inline; }
        .ticket-stack .ticket-information-block .ticket-information span b {
          letter-spacing: -0.19px;
          font-weight: 400;
          font-family: 'Galano Grotesque Medium', sans-serif; }
    .ticket-stack .ticket-information-block h6 {
      color: #000;
      font-weight: normal;
      margin-bottom: 0;
      margin-top: 0;
      letter-spacing: -0.24px;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 10px;
      width: 100%;
      line-height: 12px;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
    .ticket-stack .ticket-information-block .idealz-ticket-logo {
      width: 92px;
      margin-bottom: 10px;
      margin-left: -1px; }
    .ticket-stack .ticket-information-block .text-info {
      font-size: 10px;
      color: #000;
      letter-spacing: -0.19px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      text-align: left;
      line-height: 12px;
      font-weight: 400;
      font-family: 'Galano Grotesque', sans-serif; }
      .ticket-stack .ticket-information-block .text-info b {
        font-weight: 400;
        font-family: 'Galano Grotesque Medium', sans-serif; }
    .ticket-stack .ticket-information-block .ticket-image {
      width: 30%;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .ticket-stack .ticket-information-block .ticket-image img {
        width: 100%; }
  .ticket-stack .ticket-content {
    padding: 24px 32px 24px; }
  .ticket-stack .lower-ticket {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .ticket-stack .lower-ticket .progress-bar {
      display: none; }
    .ticket-stack .lower-ticket .ticket-number {
      color: #979797;
      letter-spacing: -0.31px;
      text-align: right;
      width: 40%;
      line-height: 12px;
      margin-top: -3px;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }
      .ticket-stack .lower-ticket .ticket-number b {
        color: #000;
        font-weight: normal;
        letter-spacing: -0.24px;
        display: block;
        font-size: 10px;
        font-weight: 400;
        font-family: 'Galano Grotesque Medium', sans-serif; }
  .ticket-stack .sold-bar {
    width: 60%;
    margin-bottom: 0;
    font-size: 10px; }
    .ticket-stack .sold-bar i {
      font-size: 10px; }
    .ticket-stack .sold-bar .item-progress {
      height: 4px; }
      .ticket-stack .sold-bar .item-progress .item-indicator {
        height: 4px; }
  .ticket-stack .timelimited-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-right: 3px; }
    .ticket-stack .timelimited-title img {
      width: 22px;
      height: 22px;
      margin-right: 3px; }
  .ticket-stack .limited-campaign-badge {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    line-height: 15px;
    letter-spacing: -0.47px;
    font-size: 11px;
    background: none;
    height: auto;
    padding: 0;
    margin: 0;
    width: auto;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .ticket-stack .limited-campaign-badge .timelimited-title {
      line-height: 15px;
      letter-spacing: -0.47px;
      font-size: 10px;
      margin: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      color: #000;
      font-weight: 400;
      font-family: 'Galano Grotesque', sans-serif; }
      .ticket-stack .limited-campaign-badge .timelimited-title img {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 3px; }
      .ticket-stack .limited-campaign-badge .timelimited-title .closingIn {
        font-style: normal;
        font-size: 11px;
        color: #000;
        font-weight: 400;
        font-family: 'Galano Grotesque Medium', sans-serif; }
    .ticket-stack .limited-campaign-badge .time-remaining {
      color: #000;
      font-size: 11px;
      margin: 4px 0 0;
      font-weight: 400;
      font-family: 'Galano Grotesque Medium', sans-serif; }

.singleCampaignTickets .ticket-stack,
.campaign-ticket .ticket-stack {
  z-index: 1001; }
  .singleCampaignTickets .ticket-stack .ticket-information-block,
  .campaign-ticket .ticket-stack .ticket-information-block {
    margin-bottom: 36px; }
  .singleCampaignTickets .ticket-stack .lower-ticket,
  .campaign-ticket .ticket-stack .lower-ticket {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .singleCampaignTickets .ticket-stack .lower-ticket .sold-bar,
    .campaign-ticket .ticket-stack .lower-ticket .sold-bar {
      width: 50%; }
    .singleCampaignTickets .ticket-stack .lower-ticket .ticket-number,
    .campaign-ticket .ticket-stack .lower-ticket .ticket-number {
      color: #979797;
      letter-spacing: -0.339px;
      font-size: 10px;
      line-height: 12px;
      white-space: nowrap;
      width: 53%;
      height: 22px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      font-weight: 400;
      font-family: 'Galano Grotesque', sans-serif; }
      .singleCampaignTickets .ticket-stack .lower-ticket .ticket-number b,
      .campaign-ticket .ticket-stack .lower-ticket .ticket-number b {
        letter-spacing: -0.2px;
        color: #000;
        font-weight: normal;
        display: inline-block;
        font-size: 10px;
        line-height: 12px;
        font-weight: 400;
        font-family: 'Galano Grotesque Medium', sans-serif; }

.singleCampaignTickets .sold-out-bar p,
.campaign-ticket .sold-out-bar p {
  font-size: 10px;
  text-align: left;
  white-space: nowrap; }

.singleCampaignTickets .sold-out-bar .sold-out-indicator,
.campaign-ticket .sold-out-bar .sold-out-indicator {
  background: #e8e8e8; }

.deactivate-page {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 400px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .deactivate-page.static-page {
    position: static;
    -webkit-transform: none;
            transform: none;
    width: 100%; }
    .deactivate-page.static-page .formAction .d-flex {
      width: 100%;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end; }
    .deactivate-page.static-page .resend-area {
      max-width: 190px; }
    .deactivate-page.static-page .global-btn {
      width: 211px; }
  .deactivate-page .white-logo {
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto; }
  .deactivate-page .global-btn {
    width: 100%; }
  .deactivate-page .revealPassword {
    border: 1px solid #ededed;
    padding: 5px 10px;
    font-size: 14px;
    color: #000;
    display: inline-block;
    border-radius: 20px;
    top: 16px;
    width: 60px;
    text-align: center; }
